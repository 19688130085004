import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import ReactGA from 'react-ga';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

Amplify.configure(awsExports);


class Game extends React.Component {
   constructor(props) {
    super(props);
    this.state = {
        contacts: [],
        keeperUserInfo: [],
        keeperExperince: [],
    }
  }

    componentDidMount(){
    this._isMounted = true;
    this.getExperienceFromCloud()
    ReactGA.initialize("G-4K0DTSELQE");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  //Fuction For HTTP GET to pull latest keeper Experience
  getExperienceFromCloud(){
         //fetch('http://18.217.153.17/keeper/getanexpereince')
         fetch('https://hksunkworks.com:/keeper/getanexpereince')
        .then(res => res.json())
        .then((data) => {
          this.setState({ keeperExperince: data })
        })
        .catch(console.log)
  }

  //block experience
  blockExperience(){
    fetch('https://hksunkworks.com:/keeper/blockExperience', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      keeperExperienceID: this.state.keeperExperince.experience_ID
    })
  });
    this.getExperienceFromCloud()
  }
    //thumbsup experience
  thumbsUpExperience(){
    fetch('https://hksunkworks.com:/keeper/thumbsUpExperience', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      keeperExperienceID: this.state.keeperExperince.experience_ID
    })
  });
    this.getExperienceFromCloud()
  }

    //thumbsdown experience
  thumbsDownExperience(){
    fetch('https://hksunkworks.com:/keeper/thumbsDownExperience', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    },
    body: JSON.stringify({
      keeperExperienceID: this.state.keeperExperince.experience_ID
    })
  });
    this.getExperienceFromCloud()
  }
  render() {
    this.output = this.state.keeperExperince.giff
    this.experienceName = this.state.keeperExperince.experience_name
    return (
     <Router>
      <div className="game" style={{backgroundColor: 'black', width: window.width, height: '1000px'}}>
       <div  style={{alignItems: 'center',justifyContent: 'center',width: window.width,backgroundColor:'#f7eded'}}>
        <div  style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
          <p style={{fontWeight: 'bold', color: 'black',fontSize: 40, margin: 0, padding: 0}}>GiffSexy</p>
        </div>
        <div  style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
          <p style={{ color: 'gray',fontSize: 15}}>Worlds Largest Collection of Cute & Sexy GIFs</p>
        </div>
      </div>

        <div className="game-board">
          <div style={{padding:15}}></div>
          <div>
            <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
              <img
                    style={{width:"30%",height:"30%"}}
                    src = {this.output}
                    alt="description"
              />
            </div>
            <div  style={{display: 'flex',alignItems: 'center',justifyContent: 'center'}}>
              <p style={{ color: 'white',fontSize: 15}}>{this.experienceName}</p>
            </div>
            <div style={{padding:-10, alignItems: 'center',justifyContent: 'center',display: 'flex'}}>
              <p style={{fontWeight: 'bold', color: 'white',fontSize: 15}}>Rate this GIF and get another sexy GIF</p>
            </div>
            <div style={{padding:-10,display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
              <button className="square" onClick={()=>this.thumbsDownExperience()}>Thumbs Down</button>
              ${70}
              <button className="square" onClick={()=>this.thumbsUpExperience()}>Thumbs Up</button>
            </div>
          </div>
        </div>
      </div>

        <div>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
          </ul>

          <hr />

          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
          */}
          <Switch>
           <Switch>
          <Route path="/:id" children={<Child />} />
        </Switch>
          </Switch>
        </div>
    </Router>
    );
  }
}

// ======================================
// You can think of these components as "pages"
// in your app.

function Home() {
  return (
    <div>
      <h2>Home</h2>
    </div>
  );
}

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

function Child() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  let { id } = useParams();

  return (
    <div>
      <h3>ID: {id}</h3>
    </div>
  );
}
// ========================================

ReactDOM.render(
  <Game />,
  document.getElementById('root')
);
